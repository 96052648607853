import React, { memo, useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './VideoCarouselItem.scss';

import GatsbyImage from 'gatsby-image';
import { customPropTypes } from '../../../util';
import { ModalContext } from '../../Layout/Layout';
import { useWindowSize } from '../../../util';
import { BREAKPOINTS } from '../../../util/motion-manager/constant';

import { ReactComponent as Play } from '../../../assets/svgs/play.svg';

function VideoCarouselItem({ data: { videoId, icon, title, tagLine, type, hasAlternatingColors } }) {
  const { setVideoId, setVideoPlayerOpen } = useContext(ModalContext);

  const windowSize = useWindowSize();
  const isDesktop = windowSize.innerWidth >= BREAKPOINTS.desktop;

  return (
    <div
      className={classnames('VideoCarouselItem', `type-${type}`, {
        'has-alternating-colors': hasAlternatingColors,
      })}
      onClick={() => {
        setVideoId(videoId);
        setVideoPlayerOpen(true);
      }}
    >
      <div className="content">
        <div className="circle-icon">
          <Play />
        </div>
        <div className="title">{title}</div>
        <div className="info">{tagLine}</div>
      </div>
      <GatsbyImage
        className="image"
        fluid={icon?.localFile.childImageSharp.fluid}
        alt={icon?.altText}
        imgStyle={{ objectPosition: isDesktop ? 0 : 'top center' }}
      />
    </div>
  );
}

VideoCarouselItem.propTypes = checkExtraProps({
  data: PropTypes.shape({
    videoId: customPropTypes.video,
    title: PropTypes.string,
    tagLine: PropTypes.string,
    type: PropTypes.string,
    hasAlternatingColors: PropTypes.bool,
  }).isRequired,
});

VideoCarouselItem.defaultProps = {
  type: 'default',
};

export default memo(VideoCarouselItem);
